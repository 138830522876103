import React, {Component} from 'react';
import {Button, Form} from 'react-bootstrap'
import DrawerMenu from '../DrawerMenu'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
class CraneScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          isProcessing: false,
          id_1c: "",
          name: ""
      }
  }
  addOrUpdate(){
      if (this.state.id_1c == '' || this.state.name == ''){
          return
      }
          var data = new FormData()
          data.append("id_1c", this.state.id_1c)
          data.append("name", this.state.name)
      if (this.props.type == "add"){
          this.setState({isProcessing: true})
          ApiManager.addItem("crane", data).then(response => {
               this.props.history.goBack()
          })
      }
      else{
           this.setState({isProcessing: true})
           ApiManager.updateItem("crane", this.props.id, data).then(response => {
               this.props.history.goBack()
           })
      }
  }
  componentDidMount(){
      if (this.props.type == "edit"){
          ApiManager.fetchItem("crane", this.props.id).then(response => {
              this.setState({id_1c: response.data.id_1c, name: response.data.name})
          })
      }
  }
  render() {
    var AddBtnTitle = this.state.isProcessing ? <span><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Пожалуйста, подождите</span> : <span>{this.props.type == "add" ? "Добавить" : "Сохранить"} </span>
    return (
        <div style={{margin: "0 auto", width: '50%'}}>
<Form>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>ID в системе 1С</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.id_1c} required onChange={event => this.setState({id_1c: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Название</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.name} required onChange={event => this.setState({name: event.target.value})}/>
  </Form.Group>
       <Button style={{width: '300px', paddingTop: '8px', marginBottom: '16px'}} variant="primary" onClick={() => this.addOrUpdate()}>
  {AddBtnTitle}
 </Button>
</Form>
        </div>
    );
  }
}
export default withRouter(CraneScreen);