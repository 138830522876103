import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
class CraneListScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          cranes: []
      }
  }
  deleteItem(id){
      ApiManager.deleteItem("crane", id).then(response => {
          var newItems = this.state.cranes.filter(crane => crane.id != id)
          this.setState({cranes: newItems})
      })
  }
  componentDidMount(){
      ApiManager.fetchCranes().then(response => {
          this.setState({cranes: response.data})
      })
  }
  render() {
    var CraneListData = this.state.cranes.map(crane => {
        var showLink = "/crane/" + crane.id
        var itemId = crane.id
        return <tr><td>{crane.id}</td><td>{crane.id_1c}</td><td>{crane.name}</td>
        <td><Link to={showLink}><ShowIcon style={{cursor: 'pointer'}} size={24} color='blue'/></Link><DeleteIcon style={{cursor: 'pointer'}} onClick={() => this.deleteItem(itemId)} size={24} color='red'/></td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>1C</th><th>Название</th><th>Действия</th></tr>
          </thead>
          <tbody>
          {CraneListData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default CraneListScreen;