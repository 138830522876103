import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap'
import DrawerMenu from '../DrawerMenu'
import { Route } from 'react-router'
import ApiManager from '../../ApiManager'
import { withRouter, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
class CatalogItemScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isProcessing: false,
      image: "",
      name: "",
      description: "",
      height: "",
      weight: "",
      weight_end: "",
      length: "",
      sort: "",
      hit: false,
      editorState: EditorState.createEmpty(),
      uploadedImages: []
    }
  }
  addOrUpdate() {
    if (this.state.image == '' || this.state.name == '' || this.state.description == '' || this.state.height == '' || this.state.weight == ''
      || this.state.weight_end == '' || this.state.length == '' || this.state.sort == '') {
      return
    }
    var data = new FormData()
    data.append("image", this.state.image)
    data.append("name", this.state.name)
    data.append("sort", this.state.sort)
    data.append("description", this.state.description)
    data.append("height", this.state.height)
    data.append("weight", this.state.weight)
    data.append("weight_end", this.state.weight_end)
    data.append("length", this.state.length)
    data.append("hit", this.state.hit ? "1" : "0")
    if (this.props.type == "add") {
      this.setState({ isProcessing: true })
      ApiManager.addItem("catalog", data).then(response => {
        this.props.history.goBack()
      })
    }
    else {
      this.setState({ isProcessing: true })
      ApiManager.updateItem("catalog", this.props.id, data).then(response => {
        this.props.history.goBack()
      })
    }
  }
  componentDidMount() {
    if (this.props.type == "edit") {
      ApiManager.fetchItem("catalog", this.props.id).then(response => {
        this.setState({
          image: response.data.image, name: response.data.name, description: response.data.description,
          weight: response.data.weight, weight_end: response.data.weight_end, length: response.data.length,
          height: response.data.height,
          sort: response.data.sort,
          hit: response.data.hit == '1' ? true : false
        })
        // const blocksFromHTML = convertFromHTML(response.data.description);
        const blocksFromHTML = htmlToDraft(response.data.description);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        this.setState({ editorState: EditorState.createWithContent(state) })
      })
    }
  }
  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
  };

  // base64 uploader
  imageUploadCallBack = file => new Promise(
    (resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      let img = new Image();
      // let url = ''
      reader.onload = function (e) {
        img.src = this.result
      }
      img.onload = function () {
        // console.log(img.src.length)
        // Zoom the canvas needed for the image (you can also define the canvas tag directly in the DOM, so that the compressed image can be directly displayed without going to base64)
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');

        // image original size
        var originWidth = this.width;
        var originHeight = this.height;

        // Maximum size limit, you can achieve image compression by setting the width and height
        var maxWidth = 400,
          maxHeight = 500;
        // target size
        var targetWidth = originWidth,
          targetHeight = originHeight;
        // Image size exceeds 300x300 limit
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > maxWidth / maxHeight) {
            // wider, size limited by width
            targetWidth = maxWidth;
            targetHeight = Math.round(maxWidth * (originHeight / originWidth));
          } else {
            targetHeight = maxHeight;
            targetWidth = Math.round(maxHeight * (originWidth / originHeight));
          }
        }
        // canvas scales the image
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // clear the canvas
        context.clearRect(0, 0, targetWidth, targetHeight);
        // Image Compression 
        context.drawImage(img, 0, 0, targetWidth, targetHeight);
        /* The first parameter is the created img object; the second three parameters are the upper left corner coordinates, and the second two are the canvas area width and height */

        // compressed image to base64 url
        /*canvas.toDataURL(mimeType, qualityArgument), mimeType The default value is 'image/png';
            * qualityArgument indicates the quality of the exported image. This parameter is valid only when exported to jpeg and webp formats. The default value is 0.92*/
        var newUrl = canvas.toDataURL('image/jpeg', 0.92);//base64 format
        // console.log(newUrl.length)

        resolve({
          data: {
            link: newUrl
          }
        })
        // You can also convert the compressed image to blob format for uploading
        // canvas.toBlob((blob) => {
        //   console.log(blob)
        //   // Pass the blob as a parameter to the backend
        // }, 'image/jpeg', 0.92)
      }
    }
  );

  render() {
    const { editorState } = this.state;
    var AddBtnTitle = this.state.isProcessing ? <span><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Пожалуйста, подождите</span> : <span>{this.props.type == "add" ? "Добавить" : "Сохранить"} </span>
    return (
      <div style={{ margin: "0 auto", width: '50%' }}>
        <Form>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Хит" checked={this.state.hit} onChange={event => this.setState({ hit: event.target.checked })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Название</Form.Label>
            <Form.Control type="text" placeholder="" value={this.state.name} required onChange={event => this.setState({ name: event.target.value })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Ссылка на изображение</Form.Label>
            <Form.Control type="text" placeholder="" value={this.state.image} required onChange={event => this.setState({ image: event.target.value })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Сортировочное значение</Form.Label>
            <Form.Control type="number" placeholder="Введите число от 1 до 500" value={this.state.sort} required onChange={event => this.setState({ sort: event.target.value })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>Описание</Form.Label>
            <div style={{ border: '1px solid #ccc', padding: '8px' }}>
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                toolbar={{
                  options: ['inline', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'],
                  inline: {
                    options: ['bold', 'italic'],
                  },
                  image: {
                    uploadCallback: this.imageUploadCallBack.bind(this),
                    urlEnabled: false,
                    uploadEnabled: true,
                    previewImage: true,
                    alt: { present: true, mandatory: false },
                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  }

                }}
                onEditorStateChange={this.onEditorStateChange}
              />
            </div>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Длина стрелы</Form.Label>
            <Form.Control type="text" placeholder="" value={this.state.length} required onChange={event => this.setState({ length: event.target.value })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Высота подъема</Form.Label>
            <Form.Control type="text" placeholder="" value={this.state.height} required onChange={event => this.setState({ height: event.target.value })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Грузоподъемность</Form.Label>
            <Form.Control type="text" placeholder="" value={this.state.weight} required onChange={event => this.setState({ weight: event.target.value })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Грузоподъемность на конце стрелы</Form.Label>
            <Form.Control type="text" placeholder="" value={this.state.weight_end} required onChange={event => this.setState({ weight_end: event.target.value })} />
          </Form.Group>
          <Button style={{ width: '300px', paddingTop: '8px', marginBottom: '16px' }} variant="primary" onClick={() => this.addOrUpdate()}>
            {AddBtnTitle}
          </Button>
        </Form>
      </div>
    );
  }
}
export default withRouter(CatalogItemScreen);