import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap'
import DrawerMenu from '../DrawerMenu'
import { Route } from 'react-router'
import ApiManager from '../../ApiManager'
import { withRouter, BrowserRouter, Switch, Redirect } from 'react-router-dom'
class NotificationScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isProcessing: false,
      date: '',
      status: 1,
      object_id: 0,
      client_id: 0,
      crane_id: 0,
      title: "",
      file: '',
      deleted_by_user: 0,
      clients: [],
      objects: [],
      cranes: []
    }
  }
  addOrUpdate() {
    if (this.state.date == '' || this.state.title == '' || this.state.file == '') {
      return
    }
    var data = new FormData()
    data.append("table", "notifications")
    data.append("status", this.state.status)
    data.append("object_id", this.state.object_id)
    data.append("client_id", this.state.client_id)
    data.append("crane_id", this.state.crane_id)
    data.append("title", this.state.title)
    data.append("file", this.state.file)
    data.append("deleted_by_user", this.state.deleted_by_user)
    data.append("date", this.state.date)
    if (this.props.type == "add") {
      this.setState({ isProcessing: true })
      ApiManager.addItem2(data).then(response => {
        this.props.history.goBack()
      })
    }
    else {
      data.append("id", this.props.id)
      this.setState({ isProcessing: true })
      ApiManager.updateItem2(data).then(response => {
        this.props.history.goBack()
      })
    }
  }
  componentDidMount() {
    ApiManager.fetchClients().then(response => {
      this.setState({ clients: response.data })
    })
    if (this.props.type == "edit") {
      ApiManager.getItem("notifications", this.props.id).then(response => {
        this.setState({
          status: response.data.status,
          object_id: response.data.object_id,
          client_id: response.data.client_id,
          crane_id: response.data.crane_id,
          title: response.data.title,
          deleted_by_user: response.data.deleted_by_user,
          date: response.data.date,
          file: response.data.file
        })
        this.selectAll(response.data.client_id, response.data.object_id)
      })
    }
  }
  selectAll(clientId, objectId) {
    ApiManager.fetchObjectsForOrders(clientId).then(response => {
      this.setState({ objects: response.data, object_id: objectId })
      ApiManager.fetchCranesForOrders(objectId).then(response => {
        this.setState({ cranes: response.data })
      })
    })
  }
  selectClient(id) {
    this.setState({ client_id: id, object_id: 0, crane_id: 0 })
    ApiManager.fetchObjectsForOrders(id).then(response => {
      this.setState({ objects: response.data })
    })
  }
  selectObject(id) {
    this.setState({ object_id: id, crane_id: 0 })
    ApiManager.fetchCranesForOrders(id).then(response => {
      this.setState({ cranes: response.data })
    })
  }
  selectCheckbox(value) {
    this.setState({ deleted_by_user: value == true ? 1 : 0 })
  }
  render() {
    var AddBtnTitle = this.state.isProcessing ? <span><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Пожалуйста, подождите</span> : <span>{this.props.type == "add" ? "Добавить" : "Сохранить"} </span>
    var ClientList = this.state.clients.map(client => {
      return <option value={client.id}>{client.company}</option>
    })
    var ObjectList = this.state.objects.map(object => {
      return <option value={object.id}>{object.name}</option>
    })
    var CraneList = this.state.cranes.map(crane => {
      return <option value={crane.id}>{crane.name}</option>
    })
    var CheckboxValue = this.state.deleted_by_user == 1 ? "on" : "off"
    return (
      <div style={{ margin: "0 auto", width: '50%' }}>
        <Form>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Клиент</Form.Label>
            <Form.Control as="select" value={this.state.client_id} onChange={event => this.selectClient(event.target.value)}>
              <option value="0">Все</option>
              {ClientList}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Объект</Form.Label>
            <Form.Control as="select" value={this.state.object_id} onChange={event => this.selectObject(event.target.value)}>
              <option value="0">Все</option>
              {ObjectList}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Кран</Form.Label>
            <Form.Control as="select" value={this.state.crane_id} onChange={event => this.setState({ crane_id: event.target.value })}>
              <option value="0">Все</option>
              {CraneList}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Статус</Form.Label>
            <Form.Control as="select" value={this.state.status} onChange={event => this.setState({ status: event.target.value })}>
              <option value="1">Новое</option>
              <option value="2">Прочитано</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Заголовок</Form.Label>
            <Form.Control type="text" placeholder="" value={this.state.title} required onChange={event => this.setState({ title: event.target.value })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Ссылка на файл</Form.Label>
            <Form.Control type="text" placeholder="" value={this.state.file} required onChange={event => this.setState({ file: event.target.value })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Дата создания</Form.Label>
            <Form.Control type="date" placeholder="" value={this.state.date} required onChange={event => this.setState({ date: event.target.value })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Удалено пользователем?</Form.Label>
            <Form.Check
              type="checkbox"
              value={CheckboxValue}
              onChange={event => this.selectCheckbox(event.target.checked)}
            />
          </Form.Group>
          <Button style={{ width: '300px', paddingTop: '8px', marginBottom: '16px', marginTop: '16px' }} variant="primary" onClick={() => this.addOrUpdate()}>
            {AddBtnTitle}
          </Button>
        </Form>
      </div>
    );
  }
}
export default withRouter(NotificationScreen);