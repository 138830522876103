import React, {Component} from 'react';
import {Button, Form} from 'react-bootstrap'
import DrawerMenu from '../DrawerMenu'
import {Route} from 'react-router'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import NavigationBar from '../NavigationBar'
import MainScreen from '../main/MainScreen'
import ClientListScreen from '../clients/ClientListScreen'
import ClientScreen from '../clients/ClientScreen'
import ManagerListScreen from '../managers/ManagerListScreen'
import ManagerScreen from '../managers/ManagerScreen'
import CraneListScreen from '../cranes/CraneListScreen'
import CraneScreen from '../cranes/CraneScreen'
import OfferListScreen from '../offers/OfferListScreen'
import OfferScreen from '../offers/OfferScreen'
import NewsListScreen from '../news/NewsListScreen'
import NewsScreen from '../news/NewsScreen'
import ExportScreen from '../export/ExportScreen'
import CatalogScreen from '../catalog/CatalogScreen'
import CatalogItemScreen from '../catalog/CatalogItemScreen'
import ObjectListScreen from '../objects/ObjectListScreen'
import ObjectScreen from '../objects/ObjectScreen'
import ObjectCraneListScreen from '../objects/ObjectCraneListScreen'
import ObjectCraneScreen from '../objects/ObjectCraneScreen'
import VideoListScreen from '../objects/VideoListScreen'
import DocumentListScreen from '../objects/DocumentListScreen'
import ScheduleListScreen from '../objects/ScheduleListScreen'
import VideoScreen from '../objects/VideoScreen'
import DocumentScreen from '../objects/DocumentScreen'
import ScheduleScreen from '../objects/ScheduleScreen'
import OrderListScreen from '../orders/OrderListScreen'
import OrderScreen from '../orders/OrderScreen'
import RequestListScreen from '../requests/RequestListScreen'
import NotificationListScreen from '../notifications/NotificationListScreen'
import NotificationScreen from '../notifications/NotificationScreen'
import ContactListScreen from '../contacts/ContactListScreen'
import ContactScreen from '../contacts/ContactScreen'

class AdminScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          openMenu: false
      }
  }
  render() {
    var RightIcon = ""
    var Title = "Главная"
    var AddUrl = ""
    var LeftIcon = "menu"
    var DefaultKey = 1
    var Page = <MainScreen/>
    if (this.props.page == "client-list"){
        Title = "Список клиентов"
        Page = <ClientListScreen/>
        RightIcon = "add"
        AddUrl = "/client/add"
    }
    if (this.props.page == "export"){
        Title = "Экспорт данных"
        Page = <ExportScreen/>
        RightIcon = ""
        AddUrl = ""
    }    
    if (this.props.page == "manager-list"){
        Title = "Список менеджеров"
        Page = <ManagerListScreen/>
        RightIcon = "add"
        AddUrl = "/manager/add"
    }
    if (this.props.page == "crane-list"){
        Title = "Список кранов в аренду"
        Page = <CraneListScreen/>
        RightIcon = "add"
        AddUrl = "/crane/add"
    }
    if (this.props.page == "order-list"){
        Title = "Список заявок"
        Page = <OrderListScreen/>
        RightIcon = "add"
        AddUrl = "/order/add"
    }    
    if (this.props.page == "request-list"){
        Title = "Список запросов КП"
        Page = <RequestListScreen/>
        RightIcon = ""
        AddUrl = ""
    }        
    if (this.props.page == "contact-list"){
        Title = "Список контактов"
        Page = <ContactListScreen client_id={this.props.match.params.id}/>
        RightIcon = "add"
        LeftIcon = "back"
        AddUrl = "/client/" + this.props.match.params.id + "/contacts/add"
    }           
    if (this.props.page == "contact-add"){
        Title = "Добавление контакт"
        Page = <ContactScreen type="add" client_id={this.props.match.params.id}/>
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "contact-edit"){
        Title = "Редактировать контакт"
        Page = <ContactScreen type="edit" contact_id={this.props.match.params.contact_id} client_id={this.props.match.params.id} />
        RightIcon = ""
        LeftIcon = "back"
    }    
    if (this.props.page == "object-list"){
        Title = "Список объектов"
        Page = <ObjectListScreen/>
        RightIcon = "add"
        AddUrl = "/object/add"
    }
    if (this.props.page == "notification-list"){
        Title = "Список уведомлений"
        Page = <NotificationListScreen/>
        RightIcon = "add"
        AddUrl = "/notification/add"
    }    
    if (this.props.page == "object-cranes"){
        Title = "Список кранов на объекте"
        Page = <ObjectCraneListScreen object_id={this.props.match.params.id}/>
        RightIcon = "add"
        LeftIcon = "back"
        AddUrl = "/object/" + this.props.match.params.id + "/cranes/add"
    }
    if (this.props.page == "object-cranes-add"){
        Title = "Добавить кран на объекте"
        Page = <ObjectCraneScreen type="add" object_id={this.props.match.params.id}/>
        RightIcon = ""
        LeftIcon = "back"
        AddUrl = ""
    }
    if (this.props.page == "object-cranes-edit"){
        Title = "Редактировать кран на объекте"
        Page = <ObjectCraneScreen type="edit" id={this.props.match.params.id}/>
        RightIcon = ""
        LeftIcon = "back"
        AddUrl = ""
    }
    if (this.props.page == "notification-add"){
        Title = "Добавление уведомления"
        Page = <NotificationScreen type="add"/>
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "notification-edit"){
        Title = "Редактировать уведомление"
        Page = <NotificationScreen type="edit" id={this.props.match.params.id} />
        RightIcon = ""
        LeftIcon = "back"
    }        
    if (this.props.page == "object-add"){
        Title = "Добавление объекта"
        Page = <ObjectScreen type="add"/>
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "object-edit"){
        Title = "Редактировать объект"
        Page = <ObjectScreen type="edit" id={this.props.match.params.id} />
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "object-cranes-videos"){
        Title = "Список видеотрансляций для крана на объекте"
        Page = <VideoListScreen id={this.props.match.params.id}/>
        RightIcon = "add"
        LeftIcon = "back"
        AddUrl = "/object/cranes/" + this.props.match.params.id + "/videos/add"
    }
    if (this.props.page == "object-cranes-videos-add"){
        Title = "Добавить видеотрансляцию"
        Page = <VideoScreen type="add" object_crane_id={this.props.match.params.id}/>
        RightIcon = ""
        LeftIcon = "back"
        AddUrl = ""
    }
    if (this.props.page == "object-cranes-videos-edit"){
        Title = "Редактировать видеотрансляцию"
        Page = <VideoScreen type="edit" id={this.props.match.params.id2}/>
        RightIcon = ""
        LeftIcon = "back"
        AddUrl = ""
    }
    if (this.props.page == "object-cranes-documents"){
        Title = "Список документов для крана на объекте"
        Page = <DocumentListScreen id={this.props.match.params.id}/>
        RightIcon = "add"
        LeftIcon = "back"
        AddUrl = "/object/cranes/" + this.props.match.params.id + "/documents/add"
    }  
    if (this.props.page == "object-cranes-documents-add"){
        Title = "Добавить документ"
        Page = <DocumentScreen type="add" object_crane_id={this.props.match.params.id}/>
        RightIcon = ""
        LeftIcon = "back"
        AddUrl = ""
    }
    if (this.props.page == "object-cranes-documents-edit"){
        Title = "Редактировать документ"
        Page = <DocumentScreen type="edit" id={this.props.match.params.id2}/>
        RightIcon = ""
        LeftIcon = "back"
        AddUrl = ""
    }
    if (this.props.page == "object-cranes-schedules"){
        Title = "Список расписаний для крана на объекте"
        Page = <ScheduleListScreen id={this.props.match.params.id}/>
        RightIcon = "add"
        LeftIcon = "back"
        AddUrl = "/object/cranes/" + this.props.match.params.id + "/schedules/add"
    }      
    if (this.props.page == "object-cranes-schedules-add"){
        Title = "Добавить график"
        Page = <ScheduleScreen type="add" object_crane_id={this.props.match.params.id}/>
        RightIcon = ""
        LeftIcon = "back"
        AddUrl = ""
    }
    if (this.props.page == "object-cranes-schedules-edit"){
        Title = "Редактировать график"
        Page = <ScheduleScreen type="edit" id={this.props.match.params.id2}/>
        RightIcon = ""
        LeftIcon = "back"
        AddUrl = ""
    }
    if (this.props.page == "offer-list"){
        Title = "Список спецпредложений"
        Page = <OfferListScreen/>
        RightIcon = "add"
        AddUrl = "/offer/add"
    }
    if (this.props.page == "news-list"){
        Title = "Список новостей"
        Page = <NewsListScreen/>
        RightIcon = "add"
        AddUrl = "/news/add"
    }
    if (this.props.page == "catalog-list"){
        Title = "Рекламный каталог"
        Page = <CatalogScreen/>
        RightIcon = "add"
        AddUrl = "/catalog/add"
    }
    if (this.props.page == "client-add"){
        Title = "Добавление клиента"
        Page = <ClientScreen type="add"/>
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "client-edit"){
        Title = "Редактировать клиента"
        Page = <ClientScreen type="edit" id={this.props.match.params.id} />
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "manager-add"){
        Title = "Добавление менеджера"
        Page = <ManagerScreen type="add"/>
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "manager-edit"){
        Title = "Редактировать менеджера"
        Page = <ManagerScreen type="edit" id={this.props.match.params.id} />
        RightIcon = ""
        LeftIcon = "back"
    }
    
    if (this.props.page == "crane-add"){
        Title = "Добавление крана"
        Page = <CraneScreen type="add"/>
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "crane-edit"){
        Title = "Редактировать кран"
        Page = <CraneScreen type="edit" id={this.props.match.params.id} />
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "offer-add"){
        Title = "Добавление спецпредложения"
        Page = <OfferScreen type="add"/>
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "offer-edit"){
        Title = "Редактировать спецпредложение"
        Page = <OfferScreen type="edit" id={this.props.match.params.id} />
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "news-add"){
        Title = "Добавление новости"
        Page = <NewsScreen type="add"/>
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "news-edit"){
        Title = "Редактировать новость"
        Page = <NewsScreen type="edit" id={this.props.match.params.id} />
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "catalog-add"){
        Title = "Добавление крана в каталог"
        Page = <CatalogItemScreen type="add"/>
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "catalog-edit"){
        Title = "Редактировать кран в каталоге"
        Page = <CatalogItemScreen type="edit" id={this.props.match.params.id} />
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "order-add"){
        Title = "Добавление заявки"
        Page = <OrderScreen type="add"/>
        RightIcon = ""
        LeftIcon = "back"
    }
    if (this.props.page == "order-edit"){
        Title = "Редактировать заявку"
        Page = <OrderScreen type="edit" id={this.props.match.params.id} />
        RightIcon = ""
        LeftIcon = "back"
    }
    return (
        <div>
        <NavigationBar leftIcon={LeftIcon} addUrl={AddUrl} rightIcon={RightIcon} title={Title} onMenu={() => {this.setState({openMenu: true})}}/>
        <DrawerMenu defaultKey={1} open={this.state.openMenu} onClose={() => this.setState({openMenu: false})}/>
        <div className="ContentBlock" style={{top: '52px', bottom: '0px', left: '0px', right: '0px', position: 'absolute'}}>
        {Page}
        </div>
        </div>
    );
  }
}
export default withRouter(AdminScreen);