import React, {Component} from 'react';
import {Button, Form} from 'react-bootstrap'
import DrawerMenu from '../DrawerMenu'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
class ContactScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          isProcessing: false,
          id_1c: '',
          client_id: props.client_id,
          last_name: "",
          first_name: "",
          middle_name: "",
          login: "",
          password: "",
          code: "",
          position: "",
          email: "",
          phone: ""
      }
  }
  addOrUpdate(){
      if (this.state.id_1c == '' || this.state.last_name == '' || this.state.position == '' || this.state.email == '' || this.state.phone == ''){
          return
      }
          var data = new FormData()
          data.append("table", "contacts")
          data.append("id_1c", this.state.id_1c)
          data.append("client_id", this.props.client_id)
          data.append("last_name", this.state.last_name)
          data.append("first_name", this.state.first_name)
          data.append("middle_name", this.state.middle_name)
          data.append("login", this.state.login)
          data.append("password", this.state.login)
          data.append("code", this.state.code)
          data.append("position", this.state.position)
          data.append("email", this.state.email)
          data.append("phone", this.state.phone)
      if (this.props.type == "add"){
          this.setState({isProcessing: true})
          ApiManager.addItem2(data).then(response => {
               this.props.history.goBack()
          })
      }
      else{
          data.append("id", this.props.contact_id)
           this.setState({isProcessing: true})
          ApiManager.updateItem2(data).then(response => {
               this.props.history.goBack()
          })           
      }
  }
  componentDidMount(){
      if (this.props.type == "edit"){
          ApiManager.getItem("contacts", this.props.contact_id).then(response => {
              this.setState({
                            id_1c: response.data.id_1c,
          client_id: response.data.client_id,
          last_name: response.data.last_name,
          first_name: response.data.first_name,
          middle_name: response.data.middle_name,
          login: response.data.login,
          password: response.data.password,
          code: response.data.code,
          position: response.data.position,
          email: response.data.email,
          phone: response.data.phone
              })
          })
      }
  }

  render() {
    var AddBtnTitle = this.state.isProcessing ? <span><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Пожалуйста, подождите</span> : <span>{this.props.type == "add" ? "Добавить" : "Сохранить"} </span>
    return (
        <div style={{margin: "0 auto", width: '50%'}}>
<Form>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>ID в 1C</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.id_1c} required onChange={event => this.setState({id_1c: event.target.value})}/>
  </Form.Group> 
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Фамилия</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.last_name} required onChange={event => this.setState({last_name: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Имя</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.first_name} required onChange={event => this.setState({first_name: event.target.value})}/>
  </Form.Group>  
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Отчество</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.middle_name} required onChange={event => this.setState({middle_name: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Логин</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.login} required onChange={event => this.setState({login: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Пароль</Form.Label>
    <Form.Control type="password" placeholder="" value={this.state.password} required onChange={event => this.setState({password: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Код-пароль</Form.Label>
    <Form.Control type="password" placeholder="" value={this.state.code} required onChange={event => this.setState({code: event.target.value})}/>
  </Form.Group>    
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Должность</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.position} required onChange={event => this.setState({position: event.target.value})}/>
  </Form.Group>     
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>E-mail</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.email} required onChange={event => this.setState({email: event.target.value})}/>
  </Form.Group>   
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Телефон</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.phone} required onChange={event => this.setState({phone: event.target.value})}/>
  </Form.Group>   
       <Button style={{width: '300px', paddingTop: '8px', marginBottom: '16px', marginTop: '16px'}} variant="primary" onClick={() => this.addOrUpdate()}>
  {AddBtnTitle}
 </Button>
</Form>
        </div>
    );
  }
}
export default withRouter(ContactScreen);