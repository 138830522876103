import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
class OfferListScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          offers: []
      }
  }
  componentDidMount(){
      ApiManager.fetchOffers().then(response => {
          this.setState({offers: response.data})
      })
  }
  deleteItem(id){
      ApiManager.deleteItem("offer", id).then(response => {
          var newClients = this.state.offers.filter(client => client.id != id)
          this.setState({offers: newClients})
      })
  }
  render() {
    var OfferListData = this.state.offers.map(offer => {
        var showLink = "/offer/" + offer.id
        var itemId = offer.id
        return <tr><td>{offer.id}</td><td>{offer.title}</td><td><a href={offer.image} target="_blank">Смотреть изображение</a></td><td>{offer.description}</td><td>{offer.text}</td>
        <td><Link to={showLink}><ShowIcon style={{cursor: 'pointer'}} size={24} color='blue'/></Link><DeleteIcon style={{cursor: 'pointer'}} onClick={() => this.deleteItem(itemId)} size={24} color='red'/></td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>Заголовок</th><th>Картинка</th><th>Краткое описание</th><th>Текст</th><th>Действия</th></tr>
          </thead>
          <tbody>
          {OfferListData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default OfferListScreen;