import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
class ObjectCraneListScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          cranes: []
      }
  }
  componentDidMount(){
      ApiManager.fetchObjectCranes(this.props.object_id).then(response => {
          this.setState({cranes: response.data})
      })
  }
  deleteItem(id){
      ApiManager.deleteObjectCrane(id).then(response => {
          var newObjects = this.state.cranes.filter(crane => crane.id != id)
          this.setState({cranes: newObjects})
      })
  }
  render() {
    var ListData = this.state.cranes.map(crane => {
        var showLink = "/object/cranes/" + crane.id
        var itemId = crane.id
        var types = {id1: "ОтсутсвуетНаОбъекте", id2: "Завезен на площадку", id4: "Идет монтаж крана", id8: "Смонтирован и не запущен", id16: "Смонтирован и работает",
            id32: "Смонтирован и остановлен", id64: "Идет демонтаж крана", id128: "Демонтирован и не вывезен", id256: "Вывезен с объекта"
        }
        var status = types["id" + crane.status]
        var url1 = "/object/cranes/" + crane.id + "/videos"
        var url2 = "/object/cranes/" + crane.id + "/documents"
        var url3 = "/object/cranes/" + crane.id + "/schedules"
        return <tr><td>{crane.id}</td><td>{crane.crane_name}</td><td>{crane.date_start}</td><td>{crane.date_end}</td><td><Link to={url2}>{crane.documents_count} шт.</Link></td><td><Link to={url3}>{crane.schedules_count} шт.</Link></td><td><Link to={url1}>{crane.video_count} шт.</Link></td><td>{status}</td>
        <td><Link to={showLink}><ShowIcon style={{cursor: 'pointer'}} size={24} color='blue'/></Link><DeleteIcon style={{cursor: 'pointer'}} onClick={() => this.deleteItem(itemId)} size={24} color='red'/></td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>Название</th><th>Дата монтажа</th><th>Дата демонтажа</th><th>Документы</th><th>Графики работ</th><th>Видеотрансляции</th><th>Статус</th><th>Действия</th></tr>
          </thead>
          <tbody>
          {ListData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default ObjectCraneListScreen;