import React from 'react';
import logo from './logo.svg';
import './App.css';
import AdminScreen from './screens/admin/AdminScreen'
import AuthScreen from './screens/AuthScreen'
import {Route} from 'react-router'
import {BrowserRouter, Switch, Redirect} from 'react-router-dom'
import ApiManager from './ApiManager'

function App() {
    if (ApiManager.getCredentials() == null){
        return (<div className="App"><AuthScreen/></div>)
    }
    var mainRoute = ApiManager.getCredentials().admin == true ? <Route exact path='/' render={props => <AdminScreen page='main'/>}/> : 
    <Route exact path='/' render={props => <Redirect to='/client/list'/>}/>
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          {mainRoute}
          <Route exact path='/export' render={props => <AdminScreen page='export'/>}/>
          <Route exact path='/client/list' render={props => <AdminScreen page='client-list'/>}/>
          <Route exact path='/client/add' render={props => <AdminScreen page='client-add'/>}/>
          <Route exact path='/client/:id' render={props => <AdminScreen page='client-edit'/>}/>
          <Route exact path='/manager/list' render={props => <AdminScreen page='manager-list'/>}/>
          <Route exact path='/manager/add' render={props => <AdminScreen page='manager-add'/>}/>
          <Route exact path='/manager/:id' render={props => <AdminScreen page='manager-edit'/>}/>
          <Route exact path='/crane/list' render={props => <AdminScreen page='crane-list'/>}/>
          <Route exact path='/crane/add' render={props => <AdminScreen page='crane-add'/>}/>
          <Route exact path='/crane/:id' render={props => <AdminScreen page='crane-edit'/>}/>
          <Route exact path='/offer/list' render={props => <AdminScreen page='offer-list'/>}/>
          <Route exact path='/offer/add' render={props => <AdminScreen page='offer-add'/>}/>
          <Route exact path='/offer/:id' render={props => <AdminScreen page='offer-edit'/>}/>
          <Route exact path='/news/list' render={props => <AdminScreen page='news-list'/>}/>
          <Route exact path='/news/add' render={props => <AdminScreen page='news-add'/>}/>
          <Route exact path='/news/:id' render={props => <AdminScreen page='news-edit'/>}/>
          <Route exact path='/catalog/list' render={props => <AdminScreen page='catalog-list'/>}/>
          <Route exact path='/catalog/add' render={props => <AdminScreen page='catalog-add'/>}/>
          <Route exact path='/catalog/:id' render={props => <AdminScreen page='catalog-edit'/>}/>
          <Route exact path='/object/list' render={props => <AdminScreen page='object-list'/>}/>
          <Route exact path='/object/add' render={props => <AdminScreen page='object-add'/>}/>
          <Route exact path='/object/:id' render={props => <AdminScreen page='object-edit'/>}/>
          <Route exact path='/object/:id/cranes' render={props => <AdminScreen page='object-cranes'/>}/>
          <Route exact path='/object/:id/cranes/add' render={props => <AdminScreen page='object-cranes-add'/>}/>
          <Route exact path='/object/cranes/:id' render={props => <AdminScreen page='object-cranes-edit'/>}/>
          <Route exact path='/object/cranes/:id/videos' render={props => <AdminScreen page='object-cranes-videos'/>}/>
          <Route exact path='/object/cranes/:id/videos/add' render={props => <AdminScreen page='object-cranes-videos-add'/>}/>
          <Route exact path='/object/cranes/videos/:id2' render={props => <AdminScreen page='object-cranes-videos-edit'/>}/>
          <Route exact path='/object/cranes/:id/documents' render={props => <AdminScreen page='object-cranes-documents'/>}/>
          <Route exact path='/object/cranes/:id/documents/add' render={props => <AdminScreen page='object-cranes-documents-add'/>}/>
          <Route exact path='/object/cranes/documents/:id2' render={props => <AdminScreen page='object-cranes-documents-edit'/>}/>
          <Route exact path='/object/cranes/:id/schedules' render={props => <AdminScreen page='object-cranes-schedules'/>}/>
          <Route exact path='/object/cranes/:id/schedules/add' render={props => <AdminScreen page='object-cranes-schedules-add'/>}/>
          <Route exact path='/object/cranes/schedules/:id2' render={props => <AdminScreen page='object-cranes-schedules-edit'/>}/>
          <Route exact path='/order/list' render={props => <AdminScreen page='order-list'/>}/>
          <Route exact path='/order/add' render={props => <AdminScreen page='order-add'/>}/>
          <Route exact path='/order/:id' render={props => <AdminScreen page='order-edit'/>}/>
          <Route exact path='/request/list' render={props => <AdminScreen page='request-list'/>}/>
          <Route exact path='/notification/list' render={props => <AdminScreen page='notification-list'/>}/>
          <Route exact path='/notification/add' render={props => <AdminScreen page='notification-add'/>}/>
          <Route exact path='/notification/:id' render={props => <AdminScreen page='notification-edit'/>}/>  
          <Route exact path='/client/:id/contacts' render={props => <AdminScreen page='contact-list'/>}/>
          <Route exact path='/client/:id/contacts/add' render={props => <AdminScreen page='contact-add'/>}/>
          <Route exact path='/client/:id/contacts/:contact_id' render={props => <AdminScreen page='contact-edit'/>}/>            
          <Redirect to="/"/>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
