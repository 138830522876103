import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
class ObjectListScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          objects: []
      }
  }
  componentDidMount(){
      ApiManager.fetchObjects().then(response => {
          this.setState({objects: response.data})
      })
  }
  deleteItem(id){
      ApiManager.deleteObject(id).then(response => {
          var newObjects = this.state.objects.filter(object => object.id != id)
          this.setState({objects: newObjects})
      })
  }
  render() {
    var ListData = this.state.objects.map(object => {
        var showLink = "/object/" + object.id
        var itemId = object.id
        var status = object.status == 1 ? "Активный" : "В архиве"
        var cranesLink = "/object/" + object.id + "/cranes"
        return <tr><td>{object.id}</td><td>{object.name}</td><td>{object.client_name}</td><td><Link to={cranesLink}><span>{object.cranes_count} шт.</span></Link></td><td>{status}</td>
        <td><Link to={showLink}><ShowIcon style={{cursor: 'pointer'}} size={24} color='blue'/></Link><DeleteIcon style={{cursor: 'pointer'}} onClick={() => this.deleteItem(itemId)} size={24} color='red'/></td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>Название объекта</th><th>Клиент</th><th>Краны в действии</th><th>Статус</th><th>Действия</th></tr>
          </thead>
          <tbody>
          {ListData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default ObjectListScreen;