import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
import UrlIcon from 'mdi-react/FolderIcon'

class ScheduleListScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          items: []
      }
  }
  componentDidMount(){
      ApiManager.fetchSchedules(this.props.id).then(response => {
          this.setState({items: response.data})
      })
  }
  deleteItem(id){
      var data = new FormData()
      data.append("id", id)
      data.append("table", "objects_cranes_schedules")
      ApiManager.deleteItem2(data).then(response => {
          var newObjects = this.state.items.filter(object => object.id != id)
          this.setState({items: newObjects})
      })
  }
  render() {
    var ListData = this.state.items.map(item => {
        var showLink = "/object/cranes/schedules/" + item.id
        var itemId = item.id
        return <tr><td>{item.id}</td><td>{item.name}</td><td><a href={item.url} target="_blank"><UrlIcon style={{cursor: 'pointer'}} size={24} color='gray'/></a></td><td>{item.date}</td><td>{item.hours}</td><td><Link to={showLink}><ShowIcon style={{cursor: 'pointer'}} size={24} color='blue'/></Link><DeleteIcon style={{cursor: 'pointer'}} onClick={() => this.deleteItem(itemId)} size={24} color='red'/></td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>Название</th><th>URL</th><th>Дата</th><th>Часы</th><th>Действия</th></tr>
          </thead>
          <tbody>
          {ListData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default ScheduleListScreen;