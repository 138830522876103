import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
import UrlIcon from 'mdi-react/FolderIcon'
class NotificationListScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          notifications: []
      }
  }
  componentDidMount(){
      ApiManager.fetchNotifications().then(response => {
          this.setState({notifications: response.data})
      })
  }
  deleteItem(id){
      var data = new FormData()
      data.append("id", id)
      data.append("table", "notifications")
      ApiManager.deleteItem2(data).then(response => {
          var newObjects = this.state.notifications.filter(notification => notification.id != id)
          this.setState({notifications: newObjects})
      })
  }
  render() {
    var ListData = this.state.notifications.map(notification => {
        var showLink = "/notification/" + notification.id
        var itemId = notification.id
        var status = notification.status == 1 ? "Новое" : "Прочитано"
        var deleted = notification.deleted_by_user == 1 ? "Да" : "Нет"
        return <tr><td>{notification.id}</td><td>{notification.client_name}</td><td>{notification.object_name}</td><td>{notification.crane_name}</td><td>{notification.title}</td><td><a href={notification.file} target="_blank"><UrlIcon style={{cursor: 'pointer'}} size={24} color='gray'/></a></td>
        <td>{status}</td><td>{notification.date}</td><td>{deleted}</td>
        <td><Link to={showLink}><ShowIcon style={{cursor: 'pointer'}} size={24} color='blue'/></Link><DeleteIcon style={{cursor: 'pointer'}} onClick={() => this.deleteItem(itemId)} size={24} color='red'/></td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>Клиент</th><th>Объект</th><th>Кран</th><th>Заголовок</th><th>Файл</th><th>Статус</th><th>Дата просмотра</th><th>Удалено пользователем?</th><th>Действия</th></tr>
          </thead>
          <tbody>
          {ListData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default NotificationListScreen;