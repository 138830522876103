import React, {Component} from 'react';
import {Button, Form} from 'react-bootstrap'
import DrawerMenu from '../DrawerMenu'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

class ExportScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          isAdding: false,
          clients: [],
          managers: [],
          clientId: 0,
          managerId: 0,
          date_from: "",
          date_to: ""
      }
  }
  componentDidMount(){
      ApiManager.fetchClients().then(response => {
          this.setState({clients: response.data})
      })
      ApiManager.fetchManagers().then(response => {
          this.setState({managers: response.data})
      })
  }
  selectClient(id){
      this.setState({clientId: id, managerId: 0})
  }
  selectManager(id){
      this.setState({clientId: 0, managerId: id})
  }
  downloadFile(){
      if (this.state.date_from == '' && this.state.date_to == '') return;
      window.open('http://193.107.237.207/api/v1/export?manager_id=' + this.state.managerId + '&client_id=' + this.state.clientId + 
      '&date_from=' + this.state.date_from + '&date_to=' + this.state.date_to, "_blank")
  }
  render() {
    var ClientList = this.state.clients.map(client => {
        return <option value={client.id}>{client.company}</option>
    })
    var ManagerList = this.state.managers.map(manager => {
        return <option value={manager.id}>{manager.last_name + " " + manager.first_name + " " + manager.middle_name}</option>
    })
    var AddBtnTitle = this.state.isAdding ? <span><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Пожалуйста, подождите</span> : <span>Скачать</span>
    return (
        <div style={{margin: "0 auto", width: '50%'}}>
<Form>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Дата c</Form.Label>
    <Form.Control type="date" placeholder="" value={this.state.date_from} required onChange={event => this.setState({date_from: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Дата по</Form.Label>
    <Form.Control type="date" placeholder="" value={this.state.date_to} required onChange={event => this.setState({date_to: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlSelect1">
    <Form.Label>Клиент</Form.Label>
    <Form.Control as="select" value={this.state.clientId} onChange={event => this.selectClient(event.target.value)}>
      <option value="0">Все</option>
      {ClientList}
    </Form.Control>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlSelect1">
    <Form.Label>Менеджер</Form.Label>
    <Form.Control as="select" value={this.state.managerId} onChange={event => this.selectManager(event.target.value)}>
      <option value="0">Все</option>
      {ManagerList}
    </Form.Control>
  </Form.Group>
       <Button style={{width: '300px', paddingTop: '8px', paddingBottom: '8px'}} variant="primary" onClick={() => this.downloadFile()}>
  {AddBtnTitle}
 </Button>
 <br/>
 <a href='http://193.107.237.207/api/v1/export_request' target='_blank'>Запросы КП</a>   |   <a target='_blank' href='http://193.107.237.207/api/v1/export_request2'>Изменение графиков крановщиков</a>
</Form>
        </div>
    );
  }
}
export default ExportScreen;