import Drawer from 'rc-drawer';
import React, {Component} from 'react';
import ReactDom from 'react-dom';
import { Menu, Icon } from 'antd';
import {withRouter, Link} from 'react-router-dom'
import 'antd/lib/style';
import 'antd/lib/menu/style';
import 'rc-drawer/assets/index.css';
import './assets/antd.css'
import ApiManager from '../ApiManager'
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
class DrawerMenu extends React.Component {
  constructor(props){
      super(props)
  }
  onTouchEnd = () => {
    this.props.onClose()
  }
  onLogout(){
      ApiManager.saveCredentials(null)
      window.location.reload()
  }
  render() {
    var isAdmin = ApiManager.getCredentials().admin
    return (
    <Drawer width="230px" open={this.props.open} handler={false} level={null}
          onChange={this.onChange}
          onClose={this.onTouchEnd}>
      <Menu
      
        style={{ height: '200%', width: 'calc(100% - 1px)' }}// 选中的线超出
        defaultSelectedKeys={[this.props.defaultKey]}
        defaultOpenKeys={['sub1']}
        mode="inline"
      >
        <Menu.Item key="1"><Link to="/client/list" onClick={() => this.onTouchEnd()}><span><Icon type="home" /><span>Клиенты</span></span></Link></Menu.Item>
        <Menu.Item key="3"><Link to="/object/list" onClick={() => this.onTouchEnd()}><span><Icon type="shopping" /><span>Объекты</span></span></Link></Menu.Item>
        <Menu.Item key="8"><Link to="/order/list" onClick={() => this.onTouchEnd()}><span><Icon type="schedule" /><span>Заявки HELP</span></span></Link></Menu.Item>
        <Menu.Item key="9"><Link to="/request/list" onClick={() => this.onTouchEnd()}><span><Icon type="schedule" /><span>Запросы КП</span></span></Link></Menu.Item>    
        <Menu.Item key="10"><Link to="/notification/list" onClick={() => this.onTouchEnd()}><span><Icon type="schedule" /><span>Уведомления</span></span></Link></Menu.Item>
        <Menu.Item key="5"><Link to="/offer/list" onClick={() => this.onTouchEnd()}><span><Icon type="unordered-list" /><span>Спецпредложения</span></span></Link></Menu.Item>
        <Menu.Item key="4"><Link to="/crane/list" onClick={() => this.onTouchEnd()}><span><Icon type="shopping" /><span>Краны в аренду</span></span></Link></Menu.Item>
        <Menu.Item key="7"><Link to="/catalog/list" onClick={() => this.onTouchEnd()}><span><Icon type="schedule" /><span>Рекламный каталог</span></span></Link></Menu.Item>
        <Menu.Item key="6"><Link to="/news/list" onClick={() => this.onTouchEnd()}><span><Icon type="picture" /><span>Новости</span></span></Link></Menu.Item>
        <Menu.Item key="2" disabled={isAdmin == "false"}><Link to="/manager/list" onClick={() => this.onTouchEnd()}><span><Icon type="appstore" /><span>Менеджеры</span></span></Link></Menu.Item>
        <Menu.Item key="0" disabled={isAdmin == "false"}><Link to="/" onClick={() => this.onTouchEnd()}><span><Icon type="home" /><span>Настройки</span></span></Link></Menu.Item>
        <Menu.Item key="31" disabled={isAdmin == "false"}><Link to="/export" onClick={() => this.onTouchEnd()}><span><Icon type="menu" /><span>Экспорт</span></span></Link></Menu.Item>
        <Menu.Item key="11" onClick={() => this.onLogout()}><Icon type="logout"/><span>Выйти</span></Menu.Item>
      </Menu>
    </Drawer>
    );
  }
}
export default withRouter(DrawerMenu);