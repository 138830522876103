import React, {Component} from 'react';
import {Button, Form} from 'react-bootstrap'
import DrawerMenu from '../DrawerMenu'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
class VideoScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          isProcessing: false,
          name: "",
          url: ""
      }
  }
    handleChange(value, formattedValue) {
    /*this.setState({
      value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
    });*/
  }
  componentDidMount(){
      if (this.props.type == "edit"){
          ApiManager.getItem("objects_cranes_video", this.props.id).then(item => {
              this.setState({
                  name: item.data.name,
                  url: item.data.url,
                  object_crane_id: item.data.object_crane_id
              })
          })
      }
      else{
          this.setState({
              object_crane_id: this.props.object_crane_id
          })
      }
  }
  addOrUpdate(){
      var data = new FormData()
      data.append("table", "objects_cranes_video")
      data.append("name", this.state.name)
      data.append("url", this.state.url)
      data.append("object_crane_id", this.state.object_crane_id)
      this.setState({isProcessing: true})
      if (this.props.type == "add"){
          ApiManager.addItem2(data).then(response => {
this.props.history.goBack()
          })
      }
      else{
          data.append("id", this.props.id)
          ApiManager.updateItem2(data).then(response => {
this.props.history.goBack()
          })
      }
  }
  render() {
    var AddBtnTitle = this.state.isProcessing ? <span><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Пожалуйста, подождите</span> : <span>{this.props.type == "add" ? "Добавить" : "Сохранить"} </span>

    return (
        <div style={{margin: "0 auto", width: '50%'}}>
<Form>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Название</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.name} required onChange={event => this.setState({name: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>URL</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.url} required onChange={event => this.setState({url: event.target.value})}/>
  </Form.Group>
       <Button style={{width: '300px', paddingTop: '8px', marginBottom: '16px'}} variant="primary" onClick={() => this.addOrUpdate()}>
  {AddBtnTitle}
 </Button>
</Form>
        </div>
    );
  }
}
export default withRouter(VideoScreen);