import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
import SortIcon from 'mdi-react/SortIcon'

class RequestListScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          requests: [],
          sortType: 'desc'
      }
  }
  componentDidMount(){
      ApiManager.fetchRequests().then(response => {
          var sortedRequests = response.data.sort((a,b) => {return new Date(a.date).getTime() - new Date(b.date).getTime()}).reverse();
          this.setState({requests: sortedRequests})
      })
  }
  sortRequests(){
      if (this.state.sortType == 'desc'){
          var sortedRequests = this.state.requests.sort((a,b) => {return new Date(a.date).getTime() - new Date(b.date).getTime()}).reverse();
          this.setState({sortType: 'asc', requests: sortedRequests})
      }
      else{
          var sortedRequests = this.state.requests.sort((a,b) => {return new Date(a.date).getTime() - new Date(b.date).getTime()}).reverse();
          this.setState({sortType: 'desc', requests: sortedRequests})
      }
  }
  render() {
    var ListData = this.state.requests.map(request => {
        return <tr><td>{request.id}</td><td>{request.date}</td><td>{request.client_name}</td><td>{request.city}</td><td>{request.period}</td><td>{request.period_date}</td><td>{request.crane_count}</td>
        <td>{request.comment}</td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>Дата запроса <SortIcon onClick={() => this.sortRequests()} style={{cursor: 'pointer'}} size={18} color='blue'/></th><th>Клиент</th><th>Город</th><th>Срок аренды</th><th>Дата начала</th><th>Количество кранов</th><th>Комментарий</th></tr>
          </thead>
          <tbody>
          {ListData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default RequestListScreen;