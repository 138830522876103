class ApiManager{
    constructor(){
        
    }
    static fetchSettings(){
        return fetch('http://193.107.237.207/api/v1/settings', {method: 'GET'})
        .then(response => response.json())   
    }
    static fetchClients(){
        return fetch('http://193.107.237.207/api/v1/client/list', {method: 'GET'})
        .then(response => response.json())   
    }
    static fetchItem(item, id){
        return fetch('http://193.107.237.207/api/v1/' + item + '/' + id, {method: 'GET'})
        .then(response => response.json())          
    }
    static addItem(item, data){
        return fetch('http://193.107.237.207/api/v1/' + item + '/add', {method: 'POST', body: data})
        .then(response => response.json())          
    }
    static updateItem(item, id, data){
        return fetch('http://193.107.237.207/api/v1/' + item + '/' + id + '/update', {method: 'POST', body: data})
        .then(response => response.json())   
    }
    static deleteItem(item, id){
        return fetch('http://193.107.237.207/api/v1/' + item + '/' + id + '/delete', {method: 'POST'})
        .then(response => response.json())          
    }
    static fetchClient(id){
        return fetch('http://193.107.237.207/api/v1/client/' + id, {method: 'GET'})
        .then(response => response.json())   
    }
    static addClient(data){
        return fetch('http://193.107.237.207/api/v1/client/add', {method: 'POST', body: data})
        .then(response => response.json())   
    }
    static updateClient(id, data){
        return fetch('http://193.107.237.207/api/v1/client/' + id + '/update', {method: 'POST', body: data})
        .then(response => response.json())   
    }
    static fetchManagers(){
        return fetch('http://193.107.237.207/api/v1/manager/list', {method: 'GET'})
        .then(response => response.json())   
    }
    static fetchCranes(){
        return fetch('http://193.107.237.207/api/v1/crane/list', {method: 'GET'})
        .then(response => response.json())   
    }
    static fetchContacts(id){
        return fetch('http://193.107.237.207/api/v1/contact/list?client_id=' + id, {method: 'GET'})
        .then(response => response.json())   
    }    
    static fetchCranesForOrders(objectId){
        return fetch('http://193.107.237.207/api/v1/order/crane/list?object_id=' + objectId, {method: 'GET'})
        .then(response => response.json())   
    }
    static fetchObjectsForOrders(clientId){
        return fetch('http://193.107.237.207/api/v1/order/object/list?client_id=' + clientId, {method: 'GET'})
        .then(response => response.json())   
    }      
    static fetchNews(){
        return fetch('http://193.107.237.207/api/v1/news/list', {method: 'GET'})
        .then(response => response.json())   
    }
    static fetchOffers(){
        return fetch('http://193.107.237.207/api/v1/offer/list', {method: 'GET'})
        .then(response => response.json())   
    }
    static fetchCatalog(){
        return fetch('http://193.107.237.207/api/v1/catalog/list', {method: 'GET'})
        .then(response => response.json())   
    }    
    static updateSettings(phone, note, about, emailsKp, emailsSchedules, emailsHelp, orderPurposes){
        var data = new FormData()
        data.append("phone", phone)
        data.append("note", note)
        data.append("about", about)
        data.append("emails_kp", emailsKp)
        data.append("emails_schedules", emailsSchedules)
        data.append("emails_help", emailsHelp)
        data.append("order_purposes", orderPurposes)
        return fetch('http://193.107.237.207/api/v1/settings', {method: 'POST', body: data})
        .then(response => response.json())   
    }
    static fetchObjects(){
        return fetch('http://193.107.237.207/api/v1/object/list', {method: 'GET'})
        .then(response => response.json())            
    }
    static fetchObjectCranes(id){
        return fetch('http://193.107.237.207/api/v1/object/' + id + '/cranes', {method: 'GET'})
        .then(response => response.json())   
    }
    static fetchDocuments(objectCraneId){
        return fetch('http://193.107.237.207/api/v1/document/list?object_crane_id=' + objectCraneId, {method: 'GET'})
        .then(response => response.json())   
    }
    static fetchVideos(objectCraneId){
        return fetch('http://193.107.237.207/api/v1/video/list?object_crane_id=' + objectCraneId, {method: 'GET'})
        .then(response => response.json())   
    }    
    static fetchSchedules(objectCraneId){
        return fetch('http://193.107.237.207/api/v1/schedule/list?object_crane_id=' + objectCraneId, {method: 'GET'})
        .then(response => response.json())   
    }    
    static addItem2(data){
        return fetch('http://193.107.237.207/api/v1/add', {method: 'POST', body: data})
        .then(response => response.json())           
    }
    static getItem(table, id){
        return fetch('http://193.107.237.207/api/v1/get?table=' + table + '&id=' + id, {method: 'GET'})
        .then(response => response.json())           
    }
    static updateItem2(data){
        return fetch('http://193.107.237.207/api/v1/update', {method: 'POST', body: data})
        .then(response => response.json())           
    }
    static deleteItem2(data){
        return fetch('http://193.107.237.207/api/v1/delete', {method: 'POST', body: data})
        .then(response => response.json())           
    }
    static deleteObject(id){
        return fetch('http://193.107.237.207/api/v1/object/' + id + '/delete', {method: 'POST'})
        .then(response => response.json())           
    }    
    static deleteObjectCrane(id){
        return fetch('http://193.107.237.207/api/v1/object/crane/' + id + '/delete', {method: 'POST'})
        .then(response => response.json())           
    }      
    static fetchOrders(){
        return fetch('http://193.107.237.207/api/v1/order/list', {method: 'GET'})
        .then(response => response.json())           
    }    
    static fetchRequests(){
        return fetch('http://193.107.237.207/api/v1/request/list', {method: 'GET'})
        .then(response => response.json())           
    }
    static fetchNotifications(){
        return fetch('http://193.107.237.207/api/v1/notification/list', {method: 'GET'})
        .then(response => response.json())           
    }       
    static auth(login, password){
        var data = new FormData();
        data.append("login", login);
        data.append("password", password);
        return fetch('http://193.107.237.207/api/v1/login', {method: 'POST', body: data})
        .then(response => response.json())        
    }
    static saveCredentials(data){
        localStorage.setItem("credentials", JSON.stringify(data))
    }
    static getCredentials(){
        if (localStorage.getItem("credentials") === null) {
            return null
        }
        return JSON.parse(localStorage.getItem("credentials"))
    }
}
export default ApiManager;