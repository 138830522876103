import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
class CatalogScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          catalog: []
      }
  }
  componentDidMount(){
      ApiManager.fetchCatalog().then(response => {
          this.setState({catalog: response.data})
      })
  }
  deleteItem(id){
      ApiManager.deleteItem("catalog", id).then(response => {
          var newItems = this.state.catalog.filter(cat => cat.id != id)
          this.setState({catalog: newItems})
      })
  }
  render() {
    var CatalogData = this.state.catalog.map(item => {
        var showLink = "/catalog/" + item.id
        var itemId = item.id
        var hitImage = item.hit == '1' ? <img src="http://193.107.237.207/images/hit.png" width={24} height={24}/> : <span/>
        return <tr><td>{item.id}</td><td>{hitImage} {item.name}</td><td><a href={item.image} target="_blank">Смотреть изображение</a></td><td>{item.height}</td><td>{item.length}</td><td>{item.weight}</td><td>{item.weight_end}</td>
        <td><Link to={showLink}><ShowIcon style={{cursor: 'pointer'}} size={24} color='blue'/></Link><DeleteIcon style={{cursor: 'pointer'}} onClick={() => this.deleteItem(itemId)} size={24} color='red'/></td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>Название</th><th>Картинка</th><th>Высота подъема</th><th>Длина стрелы</th><th>Грузоподъемность</th><th>Грузоподъемность на конце стрелы</th><th>Действия</th></tr>
          </thead>
          <tbody>
          {CatalogData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default withRouter(CatalogScreen);