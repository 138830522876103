import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
import SortIcon from 'mdi-react/SortIcon'

class NewsListScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          news: [],
          sortType: 'desc'
      }
  }
  componentDidMount(){
      ApiManager.fetchNews().then(response => {
          var sortedNews = response.data.sort((a,b) => {return new Date(a.date).getTime() - new Date(b.date).getTime()}).reverse();
          this.setState({news: sortedNews})
      })
  }
  deleteItem(id){
      ApiManager.deleteItem("news", id).then(response => {
          var newClients = this.state.news.filter(client => client.id != id)
          this.setState({news: newClients})
      })
  }
  sortNews(){
      if (this.state.sortType == 'desc'){
          var sortedNews = this.state.news.sort((a,b) => {return new Date(a.date).getTime() - new Date(b.date).getTime()}).reverse();
          this.setState({sortType: 'asc', news: sortedNews})
      }
      else{
          var sortedNews = this.state.news.sort((a,b) => {return new Date(a.date).getTime() - new Date(b.date).getTime()}).reverse();
          this.setState({sortType: 'desc', news: sortedNews})
      }
  }
  render() {
    var NewsListData = this.state.news.map(news => {
        var showLink = "/news/" + news.id
        var itemId = news.id
        return <tr><td>{news.id}</td><td>{news.title}</td><td><a href={news.image} target="_blank">Смотреть изображение</a></td><td>{news.text}</td><td>{news.date}</td>
        <td><Link to={showLink}><ShowIcon style={{cursor: 'pointer'}} size={24} color='blue'/></Link><DeleteIcon style={{cursor: 'pointer'}} onClick={() => this.deleteItem(itemId)} size={24} color='red'/></td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>Заголовок</th><th>Картинка</th><th>Текст</th><th>Дата <SortIcon onClick={() => this.sortNews()} style={{cursor: 'pointer'}} size={18} color='blue'/></th><th>Действия</th></tr>
          </thead>
          <tbody>
          {NewsListData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default NewsListScreen;