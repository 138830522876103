import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
class ManagerListScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          managers: []
      }
  }
  componentDidMount(){
      ApiManager.fetchManagers().then(response => {
          this.setState({managers: response.data})
      })
  }
  deleteItem(id){
      ApiManager.deleteItem("manager", id).then(response => {
          var newClients = this.state.managers.filter(client => client.id != id)
          this.setState({managers: newClients})
      })
  }
  render() {
    var ManagerListData = this.state.managers.map(client => {
        var showLink = "/manager/" + client.id
        var itemId = client.id
        return <tr><td>{client.id}</td><td>{client.last_name} {client.first_name} {client.middle_name}</td><td>{client.login}</td><td>{client.email}</td><td>{client.phone}</td>
        <td><Link to={showLink}><ShowIcon style={{cursor: 'pointer'}} size={24} color='blue'/></Link><DeleteIcon style={{cursor: 'pointer'}} onClick={() => this.deleteItem(itemId)} size={24} color='red'/></td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>ФИО</th><th>Логин</th><th>Email</th><th>Телефон</th><th>Действия</th></tr>
          </thead>
          <tbody>
          {ManagerListData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default ManagerListScreen;