import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
class OrderListScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          orders: []
      }
  }
  componentDidMount(){
      ApiManager.fetchOrders().then(response => {
          this.setState({orders: response.data})
      })
  }
  deleteItem(id){
      var data = new FormData()
      data.append("id", id)
      data.append("table", "orders")
      ApiManager.deleteItem2(data).then(response => {
          var newObjects = this.state.orders.filter(order => order.id != id)
          this.setState({orders: newObjects})
      })
  }
  render() {
    var ListData = this.state.orders.map(order => {
        var showLink = "/order/" + order.id
        var itemId = order.id
        var status = order.status == 1 ? "Новая" : order.status == 2 ? "В работе" : order.status == 4 ? "Выполнена" : "Отклонена"
        return <tr><td>{order.id}</td><td>{order.date}</td><td>{order.client_name}</td><td>{order.object_name}</td><td>{order.crane_name}</td><td>{status}</td>
        <td>{order.date_end}</td><td>{order.reason}</td><td>{order.person}</td><td>{order.note}</td>
        <td><Link to={showLink}><ShowIcon style={{cursor: 'pointer'}} size={24} color='blue'/></Link><DeleteIcon style={{cursor: 'pointer'}} onClick={() => this.deleteItem(itemId)} size={24} color='red'/></td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>Дата заявки</th><th>Клиент</th><th>Объект</th><th>Кран</th><th>Статус</th><th>Дата устранения</th><th>Причина вызова</th><th>Ответственное лицо</th><th>Пояснение</th><th>Действия</th></tr>
          </thead>
          <tbody>
          {ListData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default OrderListScreen;