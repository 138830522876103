import React, {Component} from 'react';
import {Button, Form} from 'react-bootstrap'
import {Route} from 'react-router'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import ApiManager from '../ApiManager'
class AuthScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          openMenu: false,
          login: "",
          password: ""
      }
  }
  onLogin(){
      ApiManager.auth(this.state.login, this.state.password).then(response => {
          if (response.success){
              ApiManager.saveCredentials(response.data)
              window.location.reload()
          }
      })
  }
  render() {
    return (
        <div>
        <div className="ContentBlock" style={{display: 'flex', alignItems: 'center', top: '0px', background: '#272c31', bottom: '0px', left: '0px', right: '0px', position: 'absolute'}}>
          <div style={{width: '30%', margin: '0 auto'}}>
          <Form>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Control type="text" placeholder="Логин" value={this.state.login} required onChange={event => this.setState({login: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Control type="password" placeholder="Пароль" value={this.state.password} required onChange={event => this.setState({password: event.target.value})}/>
  </Form.Group>
         <Button style={{width: '300px', paddingTop: '8px', marginBottom: '16px'}} variant="primary" onClick={() => this.onLogin()}>Войти</Button>
         </Form>
          </div>
        </div>
        </div>
    );
  }
}
export default AuthScreen;