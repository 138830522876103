import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
class ContactListScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          contacts: []
      }
  }
  componentDidMount(){
      ApiManager.fetchContacts(this.props.client_id).then(response => {
          this.setState({contacts: response.data})
      })
  }
  render() {
    var ListData = this.state.contacts.map(contact => {
        var showLink = "/client/" + contact.client_id + "/contacts/" + contact.id
        var itemId = contact.id
        return <tr><td>{contact.id}</td><td>{contact.last_name} {contact.first_name} {contact.middle_name}</td><td>{contact.position}</td><td>{contact.phone}</td><td>{contact.email}</td><td>{contact.login}</td>
                <td><Link to={showLink}><ShowIcon style={{cursor: 'pointer'}} size={24} color='blue'/></Link><DeleteIcon style={{cursor: 'pointer'}} onClick={() => this.deleteItem(itemId)} size={24} color='red'/></td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>ФИО</th><th>Должность</th><th>Телефон</th><th>E-mail</th><th>Логин</th><th>Действия</th></tr>
          </thead>
          <tbody>
          {ListData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default ContactListScreen;