import React, {Component} from 'react';
import {Button, Form} from 'react-bootstrap'
import DrawerMenu from '../DrawerMenu'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
class ClientScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          isProcessing: false,
          id_1c: "",
          company: "",
          company_address: "",
          company_image: "",
          company_info: "",
          inn: "",
          phone: "",
          manager_id: "0",
          managers: [],
          editorState: EditorState.createEmpty()
      }
  }
  addOrUpdate(){
      if (this.state.manager_id == '0' || this.state.phone == "" || this.state.company == ""){
          return
      }
          var data = new FormData()
          data.append("id_1c", this.state.id_1c)
          data.append("company", this.state.company)
          data.append("company_image", this.state.company_image)
          data.append("company_inn", this.state.inn)
          data.append("company_address", this.state.company_address)
          data.append("company_info", this.state.company_info)
          data.append("phone", this.state.phone)
          data.append("company", this.state.company)
          data.append("manager_id", this.state.manager_id)
      if (this.props.type == "add"){
          this.setState({isProcessing: true})
          ApiManager.addClient(data).then(response => {
this.props.history.goBack()
          })
      }
      else{
           this.setState({isProcessing: true})
           ApiManager.updateClient(this.props.id, data).then(response => {
               this.props.history.goBack()
           })
      }
  }
  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
      company_info: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
  };
  componentDidMount(){
      ApiManager.fetchManagers().then(response => {
          this.setState({managers: response.data})
      })
      if (this.props.type == "edit"){
          ApiManager.fetchClient(this.props.id).then(response => {
              this.setState({id_1c: response.data.id_1c, company: response.data.company, phone: response.data.phone,
                  manager_id: response.data.manager_id,
                  company_address: response.data.company_address, company_image: response.data.company_image, inn: response.data.company_inn,
                  company_info: response.data.company_info
              })
const blocksFromHTML = convertFromHTML(response.data.company_info);
const state = ContentState.createFromBlockArray(
  blocksFromHTML.contentBlocks,
  blocksFromHTML.entityMap
);
this.setState({editorState: EditorState.createWithContent(state)})
          })
      }
  }
  render() {
    const { editorState } = this.state;
    var AddBtnTitle = this.state.isProcessing ? <span><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Пожалуйста, подождите</span> : <span>{this.props.type == "add" ? "Добавить" : "Сохранить"} </span>
    var ManagerListData = this.state.managers.map(manager => <option value={manager.id}>{manager.last_name} {manager.first_name} {manager.middle_name}</option>)
    return (
        <div style={{margin: "0 auto", width: '50%'}}>
<Form>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>ID в системе 1С</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.id_1c} required onChange={event => this.setState({id_1c: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Название компании</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.company} required onChange={event => this.setState({company: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>URL картинки компании</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.company_image} required onChange={event => this.setState({company_image: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>ИНН компании</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.inn} required onChange={event => this.setState({inn: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Адрес компании</Form.Label>
    <Form.Control type="text" placeholder="" value={this.state.company_address} required onChange={event => this.setState({company_address: event.target.value})}/>
  </Form.Group>   
  <Form.Group controlId="exampleForm.ControlTextarea1">
    <Form.Label>О компании</Form.Label>
    <div style={{border: '1px solid #ccc', padding: '8px'}}>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
        </div>
  </Form.Group>  
  <Form.Group controlId="exampleForm.ControlInput1">
    <Form.Label>Номер телефона</Form.Label>
    <Form.Control type="phone" placeholder="+7 (XXX) XXX-XX-XX" value={this.state.phone} required onChange={event => this.setState({phone: event.target.value})}/>
  </Form.Group>
  <Form.Group controlId="exampleForm.ControlSelect1">
    <Form.Label>Выберите менеджера</Form.Label>
    <Form.Control as="select" value={this.state.manager_id} onChange={event => this.setState({manager_id: event.target.value})}>
      <option value="0">Не выбран</option>
      {ManagerListData}
    </Form.Control>
  </Form.Group>
       <Button style={{width: '300px', paddingTop: '8px', marginBottom: '16px'}} variant="primary" onClick={() => this.addOrUpdate()}>
  {AddBtnTitle}
 </Button>
</Form>
        </div>
    );
  }
}
export default withRouter(ClientScreen);