import React, {Component} from 'react';
import {Button, Form, Table} from 'react-bootstrap'
import {Route} from 'react-router'
import ApiManager from '../../ApiManager'
import {withRouter, BrowserRouter, Switch, Redirect} from 'react-router-dom'
import {Link} from 'react-router-dom'
import DeleteIcon from 'mdi-react/DeleteIcon'
import ShowIcon from 'mdi-react/FileDocumentEditOutlineIcon'
class ClientListScreen extends React.Component {
  constructor(props){
      super(props)
      this.state = {
          clients: []
      }
  }
  componentDidMount(){
      ApiManager.fetchClients().then(response => {
          this.setState({clients: response.data})
      })
  }
  deleteItem(id){
      ApiManager.deleteItem("client", id).then(response => {
          var newClients = this.state.clients.filter(client => client.id != id)
          this.setState({clients: newClients})
      })
  }
  render() {
    var ClientListData = this.state.clients.filter(client => {
        if (ApiManager.getCredentials().admin == "false"){
            return ApiManager.getCredentials().client_id.includes(parseInt(client.id))
        }
        return true
    }).map(client => {
        var showLink = "/client/" + client.id
        var contactLink = "/client/" + client.id + "/contacts"
        var clientId = client.id
        return <tr><td>{client.id}</td><td>{client.company}</td><td><Link to={contactLink}>{client.contact_count} контактов</Link></td>
        <td>{client.company_inn}</td><td>{client.phone}</td><td>{client.company_address}</td><td><Link to={showLink}><ShowIcon style={{cursor: 'pointer'}} size={24} color='blue'/></Link><DeleteIcon style={{cursor: 'pointer'}} onClick={() => this.deleteItem(clientId)} size={24} color='red'/></td></tr>
    })
    return (
        <div>
        <Table striped bordered hover>
          <thead>
          <tr><th>#</th><th>Название компании</th><th>Контакты</th><th>ИНН</th><th>Телефон</th><th>Адрес</th><th>Действия</th></tr>
          </thead>
          <tbody>
          {ClientListData}
          </tbody>
        </Table>
        </div>
    );
  }
}
export default ClientListScreen;