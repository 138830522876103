import React, {Component} from 'react';
import {Button, Form, Navbar} from 'react-bootstrap'
import {withRouter} from 'react-router-dom'
import AddIcon from 'mdi-react/AddIcon'
import MenuIcon from 'mdi-react/MenuIcon'
import ArrowBackIcon from 'mdi-react/ArrowBackIcon'
class NavigationBar extends React.Component {
  constructor(props){
      super(props)
  }
  render() {
    var RightIcon = <div></div>
    var LeftIcon = <MenuIcon style={{cursor: 'pointer'}} onClick={() => this.props.onMenu()} size={36} color='#ffffff'/>
    if (this.props.rightIcon == "add"){
        RightIcon = <AddIcon onClick={() => this.props.history.push(this.props.addUrl)} style={{cursor: 'pointer'}} size={36} color="#ffffff"/>
    }
    if (this.props.leftIcon == "back"){
        LeftIcon = <ArrowBackIcon style={{cursor: 'pointer'}} onClick={() => this.props.history.goBack()} size={36} color="#ffffff"/>
    }
    return (
            <Navbar bg="shopify" expand="true" variant="dark" fixed="top">
              <div className="pull-left">{LeftIcon}</div>
  <Navbar.Brand><font color="#ffffff">{this.props.title}</font></Navbar.Brand>
              <div className="pull-right">{RightIcon}</div>
</Navbar>
    );
  }
}
export default withRouter(NavigationBar);