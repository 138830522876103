import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap'
import DrawerMenu from '../DrawerMenu'
import { Route } from 'react-router'
import ApiManager from '../../ApiManager'
import { withRouter, BrowserRouter, Switch, Redirect } from 'react-router-dom'

class ObjectScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isProcessing: false,
      id_1c: "",
      name: "",
      client_id: 0,
      status: 1,
      cond: "",
      clients: [],
      image: "",
    }
  }

  addOrUpdate() {
    if (this.state.name == '' || this.state.client_id == 0) {
      return
    }
    var data = new FormData()
    data.append("id_1c", this.state.id_1c)
    data.append("name", this.state.name)
    data.append("client_id", this.state.client_id)
    data.append("status", this.state.status)
    data.append("image", this.state.image)
    data.append("cond", this.state.cond)
    if (this.props.type == "add") {
      this.setState({ isProcessing: true })
      ApiManager.addItem("object", data).then(response => {
        this.props.history.goBack()
      })
    }
    else {
      this.setState({ isProcessing: true })
      ApiManager.updateItem("object", this.props.id, data).then(response => {
        this.props.history.goBack()
      })
    }
  }

  componentDidMount() {
    ApiManager.fetchClients().then(response => {
      this.setState({ clients: response.data })
    })
    if (this.props.type == "edit") {
      ApiManager.fetchItem("object", this.props.id).then(response => {
        this.setState({
          id_1c: response.data.id_1c, 
          cond: response.data.cond,
          name: response.data.name,
          status: response.data.status,
          image: response.data.image,
          client_id: response.data.client_id
        })
      })
    }
  }
  render() {
    var AddBtnTitle = this.state.isProcessing ? <span><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Пожалуйста, подождите</span> : <span>{this.props.type == "add" ? "Добавить" : "Сохранить"} </span>
    var ClientListData = this.state.clients.map(client => {
      return <option value={client.id}>{client.company}</option>
    })
    return (
      <div style={{ margin: "0 auto", width: '50%' }}>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>ID в 1C</Form.Label>
            <Form.Control type="text" placeholder="" value={this.state.id_1c} required onChange={event => this.setState({ id_1c: event.target.value })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Название объекта</Form.Label>
            <Form.Control type="text" placeholder="" value={this.state.name} required onChange={event => this.setState({ name: event.target.value })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Условия крана</Form.Label>
            <Form.Control type="text" placeholder="" value={this.state.cond} required onChange={event => this.setState({ cond: event.target.value })} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Выберите клиента</Form.Label>
            <Form.Control as="select" value={this.state.client_id} onChange={event => this.setState({ client_id: event.target.value })}>
              <option value="0">Не выбран</option>
              {ClientListData}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Статус</Form.Label>
            <Form.Control as="select" value={this.state.status} onChange={event => this.setState({ status: event.target.value })}>
              <option value="1">Активный</option>
              <option value="2">В архиве</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Изображение (URL)</Form.Label>
            <Form.Control type="text" placeholder="" value={this.state.image} required onChange={event => this.setState({ image: event.target.value })} />
          </Form.Group>
          <Button style={{ width: '300px', paddingTop: '8px', marginBottom: '16px' }} variant="primary" onClick={() => this.addOrUpdate()}>
            {AddBtnTitle}
          </Button>
        </Form>
      </div>
    );
  }
}
export default withRouter(ObjectScreen);